@import "./functions.scss";
@import "./variables.scss";
@import "./mixins.scss";

// Google fonts
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

// Basic bootstrap
@import "node_modules/bootstrap/scss/reboot";

// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";

// Floating Vue (v-tooltip)
@import 'node_modules/floating-vue/dist/style';

@import "variables";

// Custom
@import "./custom";