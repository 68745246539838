@import "node_modules/bootstrap/scss/_variables.scss";

$fa-font-path: "../../../node_modules/@fortawesome/fontawesome-free/webfonts";

$font-family-base: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$primary: #d34d96;
$primary-text: #fff;
$warning: #f3af00;
$warning-text: #fff;
$danger: #e7a600;
$success: #1db954;

$header-height: 100px;
$header-color: gray;
$nav-color: #ccc;
$menu-height: 45px;
$footer-height: 200px;
$main-padding: 10px;

$list-padding: 10px;
$list-header-color: #fff;
