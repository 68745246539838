html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  font-family: $font-family-base;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.no-print {
  @media print {
    visibility: hidden;
  }
}
.print-only {
  @media screen {
    display: none !important;
  }
}